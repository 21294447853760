import { Instance, types } from 'mobx-state-tree';

export const ProcessPlanModel = types.model({
  measure: types.number,
  unit: types.string,
  updatedBy: types.string,
  updatedAt: types.string,
  createdBy: types.string,
});

export interface ProcessPlan extends Instance<typeof ProcessPlanModel> {}
