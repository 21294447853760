import { parseISO } from 'date-fns';
import { getSnapshot } from 'mobx-state-tree';

import {
  ProcessProjectList,
  ProcessStatus,
  ProcessWorkType,
} from '@app/types/Process';

import { SelfProjectStore } from '.';

enum ProcessStatusName {
  initiated = 'В работе',
  normCreated = 'Проект нормы',
  onApproval = 'На утверждении',
  deleted = 'Архив',
  approved = 'Норма',
}

export enum ProcessTypeName {
  gesn = 'ГЭСН ',
  gesnm = 'ГЭСНм ',
  gesnp = 'ГЭСНп ',
  gesnr = 'ГЭСНр ',
}

export const Views = (self: SelfProjectStore) => ({
  getStatusName(code: ProcessStatus): string {
    return ProcessStatusName[code];
  },
  formatDate(date: string): string {
    const realDate = parseISO(date);

    return `${realDate.getDate()}.${realDate.getMonth() +
      1}.${realDate.getFullYear()}, ${realDate.getHours()}:${realDate.getMinutes()}`;
  },
  getWorkTypeName(type: ProcessWorkType): string {
    return ProcessTypeName[type] || '';
  },
  getByStatus(status: ProcessStatus): ProcessProjectList[] {
    return self._fullListComplex
      ? (Object.values(
          self._fullListComplex.toJSON(),
        ) as ProcessProjectList[]).filter(proc => proc.status === status)
      : [];
  },
});
