import { Instance, types } from 'mobx-state-tree';

import { ProcessPlanModel } from '@app/types/ProcessPlan';

export enum ProcessStatus {
  Approved = 'approved',
  OnApproval = 'onApproval',
  NormCreated = 'normCreated',
  Initiated = 'initiated',
  Deleted = 'deleted',
}

export enum ProcessType {
  Complex = 'complex',
  Simple = 'simple',
}

export enum ProcessWorkType {
  Gesn = 'gesn',
  GesnM = 'gesnm',
  GesnP = 'gesnp',
  GesnR = 'gesnr',
  Enir = '',
}

export const ProcessListModel = types.model({
  id: types.string,
  name: types.string,
  code: types.string,
  status: types.enumeration(Object.values(ProcessStatus)),
  categoryCode: types.string,
  type: types.enumeration(Object.values(ProcessType)),
  workType: types.enumeration(Object.values(ProcessWorkType)),
  updatedBy: types.string,
  updatedAt: types.string,
  createdBy: types.string,
  deleted: types.maybe(types.boolean),
});

export const ProcessModel = ProcessListModel.props({
  plan: ProcessPlanModel,
});

export interface ProcessProjectList extends Instance<typeof ProcessListModel> {}
export interface ProcessProject extends Instance<typeof ProcessModel> {}
