import { LinkProps } from 'next/link';
import * as React from 'react';
import NextLink from 'next/link';

type StateFunction = (props: { currentState: any; newState: any }) => boolean;

interface LinkPrimitiveProps extends Omit<LinkProps, 'href' | 'replace'> {
  isNextLink?: boolean;
  href?: string;
  as: string;
  children?: React.ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top' | null;
  download?: boolean;
  onClick?: (event?: any) => void;
  className?: string;
  replace?: StateFunction | boolean;
  back?: boolean;
  options?: object;
  saveHistoryScroll?: boolean;
  [x: string]: any;
}

export const Link = ({
  isNextLink = true,
  children,
  href,
  back,
  onClick,
  as,
  saveHistoryScroll = true,
  scroll = false,
  replace,
  options = {},
  shallow,
  passHref,
  target,
  className,
  ...rest
}: LinkPrimitiveProps) => {
  return !isNextLink ? (
    <a href={href} onClick={onClick} target={target as string} {...rest}>
      {children}
    </a>
  ) : (
    <NextLink href={href || as} as={as} {...rest}>
      <a className={className}>{children}</a>
    </NextLink>
  );
};
